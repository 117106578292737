// Constants
import { ADDONS, ROLES } from '@/constants'
// Components
import UserMenu from '../UserNavMenu'
import AppNavMenu from '../AppNavMenu'
// Services
import { getEveryPlacesByIds } from '@/services/place'
// Mixins
import addonMixin from '@/mixins/addonsMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters, mapMutations, mapState } from 'vuex'
// Utils
import { get, isNil } from 'lodash'
import { getCurrentBrand } from '@/utils'

export default {
  name: 'AppBar',
  model: {
    prop: 'visible',
    event: 'change'
  },
  components: { AppNavMenu, UserMenu },
  mixins: [addonMixin, uiMixin],
  props: {
    /**
     * https://vuetifyjs.com/en/components/alerts/#alerts
     */
    navBarVOptions: {
      default() {
        return {
          app: true,
          color: 'white',
          dark: false,
          dense: true,
          'elevate-on-scroll': false,
          elevation: 0
        }
      },
      type: Object
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      places: [],
      // Others
      processingRequest: true
    }
  },
  computed: {
    ...mapState('app', ['largeDevice', 'showQrAlert']),
    ...mapGetters('place', ['placeData']),
    ...mapGetters('authentication', ['onboardingCompleted', 'userData']),
    ...mapGetters('company', ['companyData']),
    /**
     * Opciones de la "barra de navegación"
     *
     * @return {Object}
     */
    currentNavBarVOptions() {
      return {
        ...this.navBarVOptions
      }
    },
    /**
     * Establecimientos disponibles en el menú desplegable,
     * eliminamos de él, el establecimiento donde nos encontramos
     * y lo limitamos a 5 items
     *
     * @return {array}
     */
    availablePlaces() {
      return this.places.reduce((sumPlaces, place) => {
        if (place.id !== this.placeData.id) {
          if (sumPlaces.length < 5) {
            const addOnConfigs = get(place, 'addOnConfigs', [])
            const addOnPlace = addOnConfigs.find((config) => config.id === ADDONS.place)
            const name = !isNil(addOnPlace)
              ? get(addOnPlace, 'configFields.name', null)
              : place.name

            sumPlaces.push({
              ...place,
              name
            })
          } else if (sumPlaces.length === 5) {
            sumPlaces.push({
              name: 'Otros establecimientos...'
            })
          }
        }

        return sumPlaces
      }, [])
    },
    /**
     * Nombre de establecimiento actual
     *
     * @return {String}
     */
    placeName() {
      return get(this.placeAddonsSetupByUser, `${ADDONS.place}.name`, 'Tu establecimiento')
    },
    /**
     * Mostramos botón de "menú"?
     *
     * @return {Boolean}
     */
    showMenuButton() {
      return !this.largeDevice
    },
    /**
     * Mostramos links de "multi-establecimientos"
     *
     * @return {Boolean}
     */
    showMultiPlacesMenu() {
      return (
        this.showShareIcon &&
        this.hasMultiPlacesAddon &&
        this.userData.role === ROLES.horeca_admin.value
      )
    },
    /**
     * Mostramos icono de enlace a "referido"
     *
     * @return {Boolean}
     */
    showReferrerIcon() {
      return this.showShareIcon && getCurrentBrand(this.placeData.brand) !== 'schweppes'
    },
    /**
     * Mostramos icono de enlace a "compartida"
     *
     * @return {Boolean}
     */
    showShareIcon() {
      const currentRoute = get(this.$route, 'name', 'fakeRoute')
      return this.onboardingCompleted && currentRoute !== 'onboardingFinish'
    }
  },
  watch: {
    largeDevice: {
      handler(value) {
        this.$emit('change', value)
      },
      immediate: true
    },
    companyData: {
      async handler(data) {
        if (!isNil(data) && this.showMultiPlacesMenu) {
          await this.setPlaces()
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('place', ['setPlace', 'resetPlace']),
    ...mapMutations('app', ['setSelectedPlace', 'setShowQrAlert']),
    /**
     * Click on menu button
     */
    handleClickMenu() {
      this.$emit('change', !this.visible)
    },
    /**
     * Se setea el places seleccionado en el store y se redirigue haci­a la home
     *
     * @param {object} place - Datos del establecimiento
     */
    handleClickPlace(place) {
      // Si se trata del enlace de un establecimiento
      if (!isNil(place.id)) {
        // Reseteamos datos del establecimiento
        // y establecemos los del seleccionado
        this.resetPlace()
        this.setPlace(place)
        // Establecemos establecmiento "seleccionado"
        this.setSelectedPlace(place.id)
        // Redirigimos al dashboard
        this.routerPushTo({ name: 'dashboard' })
        // Si nos encontramos en la vista de "dashboard"
        // emitimos este evento para que vuelva a cargar
        // los datos del dashboard al cambiar de establecimiento
        this.$root.$emit('reload-dashboard-data')
      } else {
        // Redirigimos al listado de establecimientos
        this.routerPushTo({ path: '/multi-places' })
      }
    },
    /**
     * Cuando pulsamos en el enlace de "referidos"
     */
    handleClickReferrerLink() {
      this.routerPushTo({
        path: '/referrer-link'
      })
    },
    /**
     * Cuando pulsamos en el enlace de "compartir menú"
     */
    handleClickShareLink() {
      this.routerPushTo({
        path: '/share-menu-link'
      })
      // Ocultamos la alerta del QR
      this.handleToggleShareLink()
    },
    /**
     * Cuando pulsamos sobre el cierre (ocultación)
     * del texto de "compartir menu"
     */
    handleToggleShareLink() {
      this.setShowQrAlert(false)
    },
    /**
     * Listado de establecimientos relacionados con el usuario
     *
     * @return {array}
     */
    async setPlaces() {
      try {
        // Loading
        this.processingRequest = true
        // Obtenemos establecimientos asociados
        this.places = await getEveryPlacesByIds(Object.keys(this.companyData.places))
      } catch (error) {
        // Mostramos mensaje de error
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        // Loading
        this.processingRequest = false
      }
    }
  }
}
