// Contants
import { ROLES } from '@/constants'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { get } from 'lodash'
import { getEnvironmentVariable } from '@/utils'

export default {
  name: 'UserNavMenu',
  mixins: [uiMixin],
  computed: {
    ...mapGetters('authentication', ['onboardingCompleted', 'userData']),
    /**
     * Enlace de perfil del usuario
     *
     * @return {boolean}
     */
    showUserLink() {
      return this.onboadingWasCompleted && !this.userData.isManagerUser
    },
    /**
     * Mostramos los enlaces en el menú (se muestran cuando el usuario,
     * ya ha termiando el onboarding)
     *
     * @return {boolean}
     */
    showMenuLink() {
      return (
        getEnvironmentVariable('VUE_APP_BRAND') !== 'schweppes' &&
        this.onboadingWasCompleted &&
        (this.userData.role === ROLES.horeca_admin.value ||
          this.userData.role === ROLES.partner_partner.value ||
          this.userData.role === ROLES.partner_brand.value)
      )
    },
    /**
     * Se ha terminado la guía y has elegido un paquete
     *
     * @return {boolean}
     */
    onboadingWasCompleted() {
      const currentRoute = get(this.$route, 'name', 'fakeRoute')
      return this.onboardingCompleted && currentRoute !== 'onboardingFinish'
    }
  },
  methods: {
    /**
     * Close user sessions
     */
    handleCloseSession() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          await this.closeSession()
        },
        actionButtonText: 'Cerrar',
        text: '¿Estas seguro que deseas cerrar la sesión?',
        type: 'warning',
        visible: true
      })
    }
  }
}
